<template>
  <div>
    <Header></Header>

    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Address Book</h3>
                <div class="d-flex">
                  <button @click="showCreateModal = true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      /></svg
                    >&nbsp; Create New Address
                  </button>
                </div>
              </div>
              <div class="content p-3">
                <div class="filter-search">
                  Filters:&nbsp;&nbsp;
                  <input
                    type="text"
                    placeholder="Search.."
                    v-model="searchText"
                    @input="searchTable"
                  />
                  Type:&nbsp;&nbsp;
                  <select @change="typeFilter()" v-model="searchType">
                    <option value="">All</option>
                    <option value="sender">Sender</option>
                    <option value="recipient">Recipient</option>
                    <option value="billing">Billing</option>
                  </select>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Fullname</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Country</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Zip</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody v-if="!isTableLoading">
                  <tr v-for="(address, i) in addresses" :key="i">
                    <td>
                      <p>{{ address.fullname }}</p>
                    </td>
                    <td>
                      <p>{{ address.company }}</p>
                    </td>
                    <td>
                      <p>{{ address.phone }}</p>
                    </td>
                    <td>
                      <p>{{ address.country }}</p>
                    </td>
                    <td>
                      <p>{{ address.state }}</p>
                    </td>
                    <td>
                      <p>{{ address.city }}</p>
                    </td>
                    <td>
                      <p>{{ address.zip }}</p>
                    </td>
                    <td>
                      <div class="table-actions">
                        <button
                          v-b-tooltip.hover
                          title="View / Edit"
                          @click="editAddress(address.id)"
                          class="view-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                        <button
                          @click="deleteAddress(address.id)"
                          v-b-tooltip.hover
                          title="Delete Address"
                          class="delete-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="spinner-wrap" v-if="isTableLoading">
                <b-spinner variant="light" class="mr-2" medium></b-spinner>
                <div class="text">Loading please wait...</div>
              </div>
              <div class="table-bottom">
                <select @change="limitTable()" v-model="limit">
                  <option value="10">10 records</option>
                  <option value="20">20 records</option>
                  <option value="50">50 records</option>
                  <option value="100">100 records</option>
                </select>
                <div class="table-pagination">
                  <button
                    @click="changePage(page)"
                    :class="current_page == page ? 'active' : ''"
                    v-for="(page, i) in total_pages"
                    :key="i"
                  >
                    {{ page }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-modal-bg" @click="showCreateModal = false" v-if="showCreateModal"></div>
    <div class="c-modal modal-xl" v-if="showCreateModal">
      <div class="c-modal-head">
        <div class="title">Create New Address</div>
        <button @click="showCreateModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <!-- <div class="spinner-wrap">
        <b-spinner variant="light" class="mr-2" medium></b-spinner>
        <div class="text">Loading please wait...</div>
      </div> -->
      <div class="c-modal-content">
        <div class="row">
          <div class="col-12">
            <div class="v-form-item">
              <span>Save Address As</span>
              <select v-model="formData.type">
                <option selected disabled>Choose type</option>
                <option value="sender">Sender</option>
                <option value="recipient">Recipient</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Fullname</span>
              <input
                type="text"
                placeholder="Fullname"
                v-model="formData.address.fullname"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Country</span>
              <country-select
                v-model="formData.address.country"
                :country="formData.address.country"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>City</span>
              <region-select
                :regionName="true"
                v-model="formData.address.city"
                :country="formData.address.country"
                :region="formData.address.city"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>State</span>
              <select
                v-model="formData.address.state"
                v-if="formData.address.country == 'US'"
              >
                <option v-for="(state, i) in usStates" :key="i" :value="state.code">
                  {{ state.name }}
                </option>
              </select>
              <select
                v-model="formData.address.state"
                v-if="formData.address.country == 'CA'"
              >
                <option v-for="(state, i) in caStates" :key="i" :value="state.code">
                  {{ state.name }}
                </option>
              </select>
              <input
                type="text"
                placeholder="State"
                v-model="formData.address.state"
                v-if="
                  formData.address.country != 'US' && formData.address.country != 'CA'
                "
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Zip</span>
              <input type="text" placeholder="Zip" v-model="formData.address.zip" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Address Line 1</span>
              <input
                type="text"
                placeholder="Address Line 1"
                @input="addressFill()"
                v-model="formData.address.line1"
              />
              <div class="input-dropdown-list" v-if="showDropdown">
                <span
                  v-for="(item, i) in dropdownList"
                  :key="i"
                  @click="chooseAddress(item.suggestion)"
                >
                  {{ item.suggestion }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Address Line 2 (optional)</span>
              <input
                type="text"
                placeholder="Address Line 2"
                v-model="formData.address.line2"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Phone</span>
              <input type="text" placeholder="Phone" v-model="formData.address.phone" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Company</span>
              <input
                type="text"
                placeholder="Company"
                v-model="formData.address.company"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Tax Id (optional)</span>
              <input type="text" placeholder="Tax Id" v-model="formData.address.taxid" />
            </div>
          </div>
          <div class="col-12">
            <button class="main-btn" @click="handleSubmit()">Create Address</button>
          </div>
        </div>
      </div>
    </div>

    <!-- EDIT FORM MODAL -->

    <div class="c-modal-bg" @click="showEditModal = false" v-if="showEditModal"></div>
    <div class="c-modal modal-xl" v-if="showEditModal">
      <div class="c-modal-head">
        <div class="title">Edit Address</div>
        <button @click="showEditModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <div class="spinner-wrap" v-if="isAddressLoading">
        <b-spinner variant="light" class="mr-2" medium></b-spinner>
        <div class="text">Loading please wait...</div>
      </div>
      <div class="c-modal-content" v-if="!isAddressLoading">
        <div class="row">
          <div class="col-12">
            <div class="v-form-item">
              <span>Save Address As</span>
              <select v-model="editFormData.type">
                <option selected disabled>Choose type</option>
                <option value="sender">Sender</option>
                <option value="recipient">Recipient</option>
                <option value="billing" disabled>Billing</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Fullname</span>
              <input type="text" placeholder="Fullname" v-model="editFormData.fullname" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Country</span>
              <country-select
                v-model="editFormData.country"
                :country="editFormData.country"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>City</span>
              <region-select
                :regionName="true"
                v-model="editFormData.city"
                :country="editFormData.country"
                :region="editFormData.city"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>State</span>
              <select v-model="editFormData.state" v-if="editFormData.country == 'US'">
                <option v-for="(state, i) in usStates" :key="i" :value="state.code">
                  {{ state.name }}
                </option>
              </select>
              <select v-model="editFormData.state" v-if="editFormData.country == 'CA'">
                <option v-for="(state, i) in caStates" :key="i" :value="state.code">
                  {{ state.name }}
                </option>
              </select>
              <input
                type="text"
                placeholder="State"
                v-model="editFormData.state"
                v-if="editFormData.country != 'US' && editFormData.country != 'CA'"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Zip</span>
              <input type="text" placeholder="Zip" v-model="editFormData.zip" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Address Line 1</span>
              <input
                type="text"
                placeholder="Address Line 1"
                @input="addressFill()"
                v-model="editFormData.line1"
              />
              <div class="input-dropdown-list" v-if="showDropdown">
                <span
                  v-for="(item, i) in dropdownList"
                  :key="i"
                  @click="chooseAddress(item.suggestion)"
                >
                  {{ item.suggestion }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Address Line 2 (optional)</span>
              <input
                type="text"
                placeholder="Address Line 2"
                v-model="editFormData.line2"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Phone</span>
              <input type="text" placeholder="Phone" v-model="editFormData.phone" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Company</span>
              <input type="text" placeholder="Company" v-model="editFormData.company" />
            </div>
          </div>
          <div class="col-6">
            <div class="v-form-item">
              <span>Tax Id (optional)</span>
              <input type="text" placeholder="Tax Id" v-model="editFormData.taxid" />
            </div>
          </div>
          <div class="col-12">
            <button class="main-btn" @click="handleSubmitEdit()">Create Address</button>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import usStates from "../../utils/usa-states";
import caStates from "../../utils/ca-states";
//import { required } from "vuelidate/lib/validators";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";

export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      formData: {
        type: "",
        address: {
          fullname: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone: "",
          company: "",
          taxid: "",
        },
      },

      editFormData: {
        type: "",
        fullname: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        company: "",
        taxid: "",
      },

      usStates: usStates,
      caStates: caStates,

      isAddressLoading: false,
      showDropdown: false,
      dropdownList: [],
      errors: [],
      addresses: [],
      showModal: false,
      showCreateModal: false,
      showEditModal: false,
      courierDetails: {},

      page: 1,
      limit: 20,
      current_page: 0,
      total_pages: 0,
      total_records: 0,

      searchText: "",
      searchType: "",

      orderDetails: {},
      isLoading: false,
      isTableLoading: false,
    };
  },
  /* validations: {
    formData: {
      type: { required },
      address: {
        fullname: { required },
        line1: { required },
        city: { required },
        state: { required },
        zip: { required },
        country: { required },
        phone: { required },
        company: { required },
      },
    },
  }, */
  mounted() {
    this.getAddresses();
  },
  methods: {
    getAddresses() {
      this.isTableLoading = true;
      axios
        .get(`address?page=${this.page}&limit=${this.limit}&type=&search`)
        .then((res) => {

          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.addresses = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },




    searchTable() {
      if (this.searchText.length >= 3) {
        this.getAddresses();
      } else if (this.searchText.length == 0) {
        this.getAddresses();
      }
    },


    limitTable() {
      this.isTableLoading = true;
      this.page = 1;

      axios
        .get(
          `address?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchType ? "&type=" + this.searchType : ""
          }`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.addresses = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },



    typeFilter() {
      this.isTableLoading = true;
      this.page = 1;


      axios
        .get(
          `address?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchType ? "&type=" + this.searchType : ""
          }`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.addresses = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    changePage(page) {
      this.isTableLoading = true;
      this.page = page;

      axios
        .get(
          `address?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchType ? "&type=" + this.searchType : ""
          }`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.addresses = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    addressFill() {
      if (this.formData.address.line1.length >= 3) {
        console.log(this.formData.address.line1);
        this.showDropdown = true;

        setTimeout(() => {
          axios
            .get(
              `https://api.addresszen.com/v1/autocomplete/addresses?query=${this.formData.address.line1}&api_key=ak_test&context=USA`
            )
            .then((res) => {
              this.dropdownList = res.data.result.hits;
            })
            .catch((err) => {
              this.errors.push(err);
            });
        }, 600);
      }
    },

    chooseAddress(address) {
      this.formData.address.line1 = address;
      this.showDropdown = false;
    },

    handleSubmit() {
      console.log(this.formData);
      axios
        .post(`address`, this.formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == "ADDRESS_CREATED") {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-right",
            });
            this.formData = {};
            this.showCreateModal = false;
            this.getAddresses();
          }
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },

    deleteAddress(id) {
      axios
        .delete(`address/${id}`)
        .then((res) => {
          this.$toast.open({
            message: res.data.message,
            type: "success",
            position: "top-right",
          });
          this.getAddresses();
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },

    editAddress(id) {
      this.showEditModal = true;
      this.isAddressLoading = true;

      axios
        .get(`address/${id}`)
        .then((res) => {
          this.editFormData = res.data;
          this.isAddressLoading = false;
          console.log(res.data);
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },

    handleSubmitEdit() {
      console.log(this.editFormData);

      const newData = {
        type: this.editFormData.type,
        address: {
          fullname: this.editFormData.fullname,
          line1: this.editFormData.line1,
          line2: this.editFormData.line2,
          city: this.editFormData.city,
          state: this.editFormData.state,
          zip: this.editFormData.zip,
          country: this.editFormData.country,
          phone: this.editFormData.phone,
          company: this.editFormData.company,
          taxid: this.editFormData.taxid,
        },
      };

      axios
        .put(`address/${this.editFormData.id}`, newData)
        .then((res) => {
          console.log(res.data);
          this.$toast.open({
            message: res.data.message,
            type: "success",
            position: "top-right",
          });
          this.editFormData = {};
          this.showEditModal = false;
          this.getAddresses();
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
